import React from 'react';

// Components
import {
  Layout,
  Container,
  Statement,
  Partners,
  // Staff,
  Testimonial,
  Carousel,
} from '../components';

// Data
import data from '../data/pages/about.json';
import testimonials from '../data/testimonials.json';

const About = () => {
  const content = data.content;

  return (
    <Layout pageContext={data}>
      <Container offsetHero>
        {/* Partners */}
        <Partners
          title={content.partners.title}
          text={content.partners.text}
          companies={content.partners.companies}
        />
      </Container>

      {/* CTA Statement*/}
      <Container>
        <Statement
          bgImgSrc={content.statement.imagePath}
          title={content.statement.title}
          cta={content.statement.cta}
          styleType="dark">
          <p>{content.statement.text}</p>
        </Statement>
      </Container>

      {/* Testimonials */}
      <Container gutter={{ top: '10rem', bottom: '10rem' }}>
        <Carousel
          title="We Build Lasting Trusted Relationships with our Clients"
          subtitle="What our clients say"
          // overrideSettings={{ autoplay: true, autoplaySpeed: 5000 }}
        >
          {testimonials.data.map((testimonial, index) => (
            <div key={index}>
              <Testimonial
                text={testimonial.text}
                author={testimonial.author}
              />
            </div>
          ))}
        </Carousel>
      </Container>

      {/* Staff */}
      {/* <Container gutter={{ top: '10rem', bottom: '10rem' }}>
        <Staff data={content.staff} />
      </Container> */}
    </Layout>
  );
};

export default About;
